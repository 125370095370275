import React, { Component } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Features } from 'constants/feature_names'
import { LOCAL_STORAGE_KEYS } from 'constants/account'
import { PERMISSION_KEYS } from 'constants/account/permissions'
import {
  INVENTORY_URLS,
  LAUNCH_ROOT_URL,
  LISTING_BUILDER_BASE,
  MARKET_INSIGHTS_URLS,
  PPC_SYNC_RETURN_URL,
  SALES_ANALYTICS_URLS,
  PRODUCT_INSIGHTS_URLS,
  SELLING_PARTNER_SYNC_CALLBACK_URL,
  RANK_TRACKER_V2_BASE,
  SHARE_OF_VOICE_URL,
  ADVERTISING_URLS,
  KEYWORD_LISTS_URL,
  PRODUCT_COSTS_AND_SETTINGS_URL,
  DEVELOPER_URL,
  COMPETITIVE_INTELLIGENCE_URL
} from 'constants/routes'
import { COBALT_APP_TYPE, DEFAULT_APP_TYPE } from 'constants/themes'
import { LIMIT_KEYS } from 'constants/feature_limits'

import { getMatchingRedirectRoute } from 'helpers/redirects'
import { hasAccess } from 'helpers/permissions'
import { randomString } from 'helpers/strings'
import { setLocalData } from 'helpers/storage'
import { hasFeatureLimit } from 'helpers/feature_limits'

import { AuthRoute } from './components/common/routes/AuthRoute/AuthRoute'
import { UnAuthRoute } from './components/common/routes/UnAuthRoute/UnAuthRoute'
import { AdminRoute } from './components/common/routes/AdminRoute/AdminRoute'
import { DynamicRoute } from './components/common/routes/DynamicRoute/DynamicRoute'
import AccessRoute from './components/common/routes/AccessRoute'
import LoadingPage from './components/common/LoadingPage'
import { FourZeroFourPage } from './components/FourZeroFourPage'

export class Routes extends Component {
  componentDidMount() {
    // this is the default title as in the index.html file
    // resetting the title on unmount ensures we don't leave the title hanging for
    // pages which haven't been updated yet
    this.props.history.listen(() => {
      document.title = 'Jungle Scout'
    })
  }

  render() {
    const { props } = this
    const {
      globalData: { appType, user },
      globalData
    } = props
    const { permissions, has_public_api_access } = user

    const isCobalt =
      appType === COBALT_APP_TYPE ||
      window?.location?.origin?.includes('cobalt')

    const orangeBaseRedirectRoute = '/dashboard'
    const cobaltBaseRedirectRoute =
      has_public_api_access && permissions.marketing_insights !== 'write'
        ? '/developer'
        : MARKET_INSIGHTS_URLS.segmentManager

    const baseRedirectRoute = isCobalt
      ? cobaltBaseRedirectRoute
      : orangeBaseRedirectRoute

    const redirectRoute = getMatchingRedirectRoute()
    if (redirectRoute) {
      window.location.href = redirectRoute
      return <Route path="/" component={LoadingPage} />
    }

    const searchParams = new URLSearchParams(window.location.search)
    const amazonRedirectUrl = searchParams.get('amazon_callback_uri')

    if (user?.id && amazonRedirectUrl) {
      const amazonState = searchParams.get('amazon_state')

      const stateParam = randomString(20)

      const state = {
        stateParam,
        expires: moment(Date.now())
          .add(1, 'h')
          .toString()
      }

      setLocalData(
        LOCAL_STORAGE_KEYS.MWS_SYNC_REDIRECT_STATE,
        JSON.stringify(state)
      )

      const redirectUri =
        window.location.origin + SELLING_PARTNER_SYNC_CALLBACK_URL

      window.location.href = `${amazonRedirectUrl}?redirect_uri=${redirectUri}&amazon_state=${amazonState}&state=${stateParam}`
      return <Route path="/" component={LoadingPage} />
    }

    const hasSupplierAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.SUPPLIERS
    )
    const hasAcademyAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.ACADEMY
    )
    const hasKeywordAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.KEYWORDS
    )
    const hasFindProductsAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.FIND_PRODUCTS
    )
    const hasToolboxAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.TOOLBOX
    )
    const hasReviewAnalysisAccess = hasFeatureLimit(
      globalData?.featureLimits,
      LIMIT_KEYS.aiFeatures
    )

    const hasAlertsAccess = hasAccess(user.permissions, PERMISSION_KEYS.ALERTS)
    const hasLaunchAccess =
      (appType === DEFAULT_APP_TYPE &&
        hasAccess(user.permissions, PERMISSION_KEYS.LAUNCH)) ||
      appType === COBALT_APP_TYPE

    const hasDeveloperAccess = hasAccess(
      user.permissions,
      PERMISSION_KEYS.PUBLIC_API
    )

    return (
      <IntlProvider>
        <Switch>
          <Redirect exact from="/" to={baseRedirectRoute} />
          <Redirect exact from="/grader" to="/toolbox/listing-grader" />
          <Redirect exact from="/onboard" to="/setup" />
          <DynamicRoute
            path="/registrations"
            load={() => import('./containers/registrations/RegistrationPage')}
          />
          <DynamicRoute
            path="/checkout"
            load={() => import('./containers/registrations/RegistrationPage')}
            {...props}
          />
          <Route
            path="/reset-password"
            component={() => {
              window.location.href = `${process.env.REACT_APP_CLIENT_LOGIN}/reset-password`
              return null
            }}
          />
          <Route
            exact
            path="/login"
            component={() => {
              window.location.href = process.env.REACT_APP_CLIENT_LOGIN
              return null
            }}
          />
          <UnAuthRoute
            exact
            path="/add-user"
            load={() =>
              import('./containers/additional_users/AdditionalUsersPage')
            }
            {...props}
          />
          <DynamicRoute
            exact
            path="/extension-uninstall"
            load={() =>
              import('./containers/extension/UninstallPage/UninstallPage')
            }
            {...props}
          />
          <AuthRoute
            exact
            path="/redirect"
            load={() => import('./components/RedirectPage')}
            globalData={globalData}
          />
          <AuthRoute
            exact
            path="/setup"
            load={() => import('./components/setup_wizard_v2/SetupWizardPage')}
            globalData={globalData}
            reducers={[() => import('reducers/review_automation')]}
          />
          <AuthRoute
            exact
            path="/extension-onboarding"
            load={() =>
              import(
                './components/extension_onboarding/ExtensionOnboardingPage'
              )
            }
            globalData={globalData}
          />
          <AuthRoute
            exact
            path="/cancel"
            load={() => import('./containers/account/CancelSubscriptionPage')}
            globalData={globalData}
          />
          <AuthRoute
            exact
            path="/cancel-confirm"
            load={() => import('./containers/account/ConfirmCancellationPage')}
            globalData={globalData}
          />
          <AuthRoute
            path="/dashboard"
            load={() => import('./components/dashboard/DashboardPage')}
            globalData={globalData}
          />
          <AccessRoute
            exact
            hasAccess={hasFindProductsAccess}
            path="/tracker"
            load={() =>
              import('./components/product_tracker/TrackerPage/TrackerPage')
            }
            globalData={globalData}
          />
          <AccessRoute
            exact
            hasAccess={hasFindProductsAccess}
            path="/database"
            load={() =>
              import('./components/product_database/ProductDatabasePage')
            }
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            noMatchProp
            {...props}
          />
          <AccessRoute
            hasAccess={hasSupplierAccess}
            path="/supplier/supplier-tracker"
            load={() =>
              import(
                './components/suppliers/supplier_tracker/SupplierTrackerPage'
              )
            }
            {...props}
          />
          <AccessRoute
            hasAccess={hasSupplierAccess}
            path="/supplier"
            load={() =>
              import(
                './components/suppliers/supplier_database/SuppliersSearchPage'
              )
            }
            globalData={globalData}
          />
          <AccessRoute
            exact
            hasAccess={hasFindProductsAccess}
            path="/opportunity-finder"
            load={() =>
              import('./containers/opportunity_finder/OpportunityFinderPage')
            }
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            noMatchProp
          />
          <AccessRoute
            hasAccess={hasAccess(
              user.permissions,
              PERMISSION_KEYS.COMPETITIVE_INTELLIGENCE
            )}
            path={COMPETITIVE_INTELLIGENCE_URL}
            load={() =>
              import(
                './components/competitive_intelligence/CompetitiveIntelligenceRouter'
              )
            }
            globalData={globalData}
            appAccessList={[DEFAULT_APP_TYPE]}
            feature={Features.COMPETITIVE_INTELLIGENCE_MVP}
          />
          <AccessRoute
            exact
            hasAccess={hasFindProductsAccess}
            path="/category-trends"
            load={() =>
              import(
                './components/category_trends/CategoryTrendsPage/CategoryTrendsPage'
              )
            }
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            noMatchProp
          />
          <AccessRoute
            exact
            hasAccess={hasKeywordAccess}
            path="/keyword"
            load={() => import('./components/keyword_scout/KeywordScoutPage')}
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            noMatchProp
          />
          <AccessRoute
            hasAccess={hasKeywordAccess}
            path={KEYWORD_LISTS_URL}
            load={() => import('./components/keyword_lists/KeywordListsPage')}
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
          />
          <AccessRoute
            hasAccess={hasKeywordAccess}
            globalData={globalData}
            path={LISTING_BUILDER_BASE}
            load={() =>
              import('./components/listing_builder/ListingBuilderRouter')
            }
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
          />
          <AuthRoute
            path={RANK_TRACKER_V2_BASE}
            load={() => import('./components/rank_tracker/RankTrackerRouter')}
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
          />
          <AccessRoute
            hasAccess={hasAcademyAccess}
            path="/academy"
            load={() => import('./containers/academy/AcademyRouter')}
            globalData={globalData}
            reducers={[() => import('reducers/academy')]}
          />
          <AccessRoute
            hasAccess={hasDeveloperAccess}
            globalData={globalData}
            path={DEVELOPER_URL}
            load={() => import('./components/developer/DeveloperPage')}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
          />
          <AccessRoute
            hasAccess={hasAlertsAccess}
            path="/alerts"
            load={() => import('./containers/alerts/AlertsPage')}
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
          />
          <AuthRoute
            exact
            path="/extension"
            load={() => import('./containers/extension/ExtensionPage')}
            globalData={globalData}
          />
          <AuthRoute
            path="/account"
            load={() => import('./containers/account/AccountPage')}
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
          />
          <AuthRoute
            path={MARKET_INSIGHTS_URLS.segmentManager}
            load={() =>
              import(
                './containers/market_insights/segments/SegmentManagerPage/SegmentManagerPage'
              )
            }
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE]}
          />
          <AuthRoute
            path={MARKET_INSIGHTS_URLS.shareAndTrends}
            load={() =>
              import(
                './containers/market_insights/share_and_trends/ShareAndTrendsPage/ShareAndTrendsPage'
              )
            }
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE]}
          />
          <AuthRoute
            path={MARKET_INSIGHTS_URLS.shareAndTrendsBase}
            load={() =>
              import(
                './containers/market_insights/share_and_trends/ShareAndTrendsBase/ShareAndTrendsBase'
              )
            }
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE]}
          />
          <AuthRoute
            path={PRODUCT_INSIGHTS_URLS.asinTracker}
            load={() =>
              import(
                './containers/product_insights/asin_tracker/AsinTrackerPage/AsinTrackerPage'
              )
            }
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE]}
          />
          <AuthRoute
            path={SHARE_OF_VOICE_URL}
            load={() =>
              appType === COBALT_APP_TYPE
                ? import('./components/share_of_voice/ShareOfVoicePage')
                : import('./components/share_of_voice_orange/ShareOfVoicePage')
            }
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
          />
          <AdminRoute
            path="/admin"
            load={() => import('./containers/admin/AdminParent')}
            reducers={[() => import('reducers/admin')]}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            {...props}
          />
          <AccessRoute
            hasAccess={hasLaunchAccess}
            path={LAUNCH_ROOT_URL}
            load={() => import('./containers/launch/LaunchRouter')}
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
          />
          <AccessRoute
            hasAccess={hasAccess(user.permissions, PERMISSION_KEYS.MY_PRODUCTS)}
            path={PRODUCT_COSTS_AND_SETTINGS_URL}
            load={() =>
              import(
                './components/product_costs_and_settings/ProductCostsAndSettingsPage'
              )
            }
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            sellerFeature
            {...props}
          />
          <AuthRoute
            path="/seller-tools"
            load={() =>
              import('./containers/seller_tools/SellerToolsLandingPage')
            }
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            {...props}
          />
          <AccessRoute
            hasAccess={hasAccess(
              user.permissions,
              PERMISSION_KEYS.SALES_ANALYTICS
            )}
            path={SALES_ANALYTICS_URLS.expenses}
            load={() =>
              import('./containers/sales_analytics/expenses/ExpensesPage')
            }
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            sellerFeature
            {...props}
          />
          <AccessRoute
            hasAccess={hasAccess(
              user.permissions,
              PERMISSION_KEYS.SALES_ANALYTICS
            )}
            path={SALES_ANALYTICS_URLS.profitAndLoss}
            load={() =>
              import(
                './containers/sales_analytics/profit_and_loss/ProfitAndLossPage'
              )
            }
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            sellerFeature
            {...props}
          />
          <AccessRoute
            hasAccess={hasAccess(
              user.permissions,
              PERMISSION_KEYS.SALES_ANALYTICS
            )}
            path={SALES_ANALYTICS_URLS.profitOverview}
            load={() =>
              import(
                './containers/sales_analytics/profit_overview/ProfitOverviewPage'
              )
            }
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            sellerFeature
            noMatchProp
            {...props}
          />
          <AccessRoute
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            hasAccess={hasAccess(user.permissions, PERMISSION_KEYS.ADVERTISING)}
            load={() =>
              import('./components/advertisement_analytics/AdvertisementPage')
            }
            noMatchProp
            path={ADVERTISING_URLS.analytics}
            sellerFeature
            {...props}
          />
          <AccessRoute
            hasAccess={hasAccess(user.permissions, PERMISSION_KEYS.MY_PRODUCTS)}
            path={INVENTORY_URLS.inventoryForecast}
            load={() =>
              import(
                './containers/inventory/inventory_forecast/InventoryForecastPage'
              )
            }
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            sellerFeature
            {...props}
          />
          <AuthRoute
            path={SELLING_PARTNER_SYNC_CALLBACK_URL}
            load={() => import('./containers/mws_ppc_sync/MwsSyncRedirectPage')}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            globalData={globalData}
          />
          <AuthRoute
            path={PPC_SYNC_RETURN_URL}
            load={() =>
              import(
                './containers/account/PpcSyncRedirectPage/PpcSyncRedirectPage'
              )
            }
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
            {...props}
          />
          <DynamicRoute
            path="/unsubscribe_email/:type/:unsub_hash"
            load={() =>
              import(
                './containers/email_unsubscribe_link/EmailUnsubscribeLinkPage'
              )
            }
            appType={appType}
          />
          <AccessRoute
            exact
            hasAccess={hasToolboxAccess}
            path="/toolbox/sales-estimator"
            load={() =>
              import('./components/sales_estimator/SalesEstimatorPage')
            }
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
          />
          <AccessRoute
            exact
            hasAccess={hasToolboxAccess}
            path="/toolbox/listing-grader"
            load={() => import('./components/listing_grader/ListingGrader')}
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
          />
          <AccessRoute
            exact
            hasAccess={hasToolboxAccess && hasReviewAnalysisAccess}
            path="/toolbox/review-analysis"
            load={() =>
              import('./components/review_analysis/ReviewAnalysisPage')
            }
            globalData={globalData}
            appAccessList={[DEFAULT_APP_TYPE]}
          />
          <AccessRoute
            exact
            hasAccess
            feature={Features.INVENTORY_REIMBURSEMENTS}
            path="/inventory-reimbursements"
            load={() =>
              import(
                './containers/inventory_reimbursements/InventoryReimbursementsPage'
              )
            }
            globalData={globalData}
            appAccessList={[COBALT_APP_TYPE, DEFAULT_APP_TYPE]}
          />

          {/* NOTE if you're addding anything new, make sure to add it above this 404 page, the 404 is a catch all and should be the last thing */}
          <Route component={FourZeroFourPage} />
        </Switch>
      </IntlProvider>
    )
  }
}

Routes.propTypes = {
  globalData: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired
}

export default Routes
